import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const minutesToISO8601 = (min) => {
  const days = Math.floor(min / 1440);
  const minSubDays = min - days * 1440;
  const hours = Math.floor(minSubDays / 60);
  const minSubHours = minSubDays - hours * 60;

  const dur = ["PT"];
  if (days > 0) {
    dur.push(`${days}D`);
  }
  if (hours > 0) {
    dur.push(`${hours}H`);
  }
  dur.push(`${minSubHours}M`);

  return dur.join("");
};

export const getRecipeJSON = (data, img, logo) => {
  const prepTime = data.preparationTimeInMinutes || 0;
  const cookTime = data.cookingTimeInMinutes || 0;
  const nutrition = {
    "@type": "NutritionInformation",
    servingSize: data.servingSizeAmount,
  };
  if (data.calories) {
    nutrition.calories = data.calories;
  }
  if (data.protein) {
    nutrition.proteinContent = data.protein;
  }
  if (data.carbohydrates) {
    nutrition.carbohydrateContent = data.carbohydrates;
  }
  if (data.fats) {
    nutrition.fatContent = data.fats;
  }
  return {
    "@context": "https://schema.org",
    "@type": "Recipe",
    mainEntityOfPage: "http://example.ampproject.org/recipe-metadata.html",
    name: data.title,
    image: img && {
      "@type": "ImageObject",
      url: img,
    },
    author: {
      "@type": "Organization",
      name: "Activia",
    },
    datePublished: data.createdAt,
    description: data.description,
    prepTime: data.preparationTimeInMinutes && minutesToISO8601(prepTime),
    cookTime: data.cookingTimeInMinutes && minutesToISO8601(cookTime),
    totalTime: minutesToISO8601(prepTime + cookTime),
    recipeYield: data.servingsAmount,
    nutrition: nutrition,
    ingredients: data.ingredientsList,
    recipeInstructions: data.steps
      .map(({ description }) =>
        documentToPlainTextString(JSON.parse(description.doc.raw))
      )
      .join("\n\n"),
    publisher: {
      "@type": "Organization",
      name: "Activia",
      logo: {
        "@type": "ImageObject",
        url: logo,
      },
    },
  };
};
